exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-3-d-lettering-in-procreate-tsx": () => import("./../../../src/pages/3d-lettering-in-procreate.tsx" /* webpackChunkName: "component---src-pages-3-d-lettering-in-procreate-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-beautiful-lettering-tsx": () => import("./../../../src/pages/beautiful-lettering.tsx" /* webpackChunkName: "component---src-pages-beautiful-lettering-tsx" */),
  "component---src-pages-fantasy-birds-blooms-tsx": () => import("./../../../src/pages/fantasy-birds-blooms.tsx" /* webpackChunkName: "component---src-pages-fantasy-birds-blooms-tsx" */),
  "component---src-pages-ff-df-tsx": () => import("./../../../src/pages/ff-df.tsx" /* webpackChunkName: "component---src-pages-ff-df-tsx" */),
  "component---src-pages-flat-florals-in-procreate-tsx": () => import("./../../../src/pages/flat-florals-in-procreate.tsx" /* webpackChunkName: "component---src-pages-flat-florals-in-procreate-tsx" */),
  "component---src-pages-gouache-botanicals-in-procreate-tsx": () => import("./../../../src/pages/gouache-botanicals-in-procreate.tsx" /* webpackChunkName: "component---src-pages-gouache-botanicals-in-procreate-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-messy-watercolors-in-procreate-tsx": () => import("./../../../src/pages/messy-watercolors-in-procreate.tsx" /* webpackChunkName: "component---src-pages-messy-watercolors-in-procreate-tsx" */),
  "component---src-pages-pattern-playground-tsx": () => import("./../../../src/pages/pattern-playground.tsx" /* webpackChunkName: "component---src-pages-pattern-playground-tsx" */),
  "component---src-pages-procreate-for-beginners-tsx": () => import("./../../../src/pages/procreate-for-beginners.tsx" /* webpackChunkName: "component---src-pages-procreate-for-beginners-tsx" */),
  "component---src-pages-procreate-patterns-df-tsx": () => import("./../../../src/pages/procreate-patterns-df.tsx" /* webpackChunkName: "component---src-pages-procreate-patterns-df-tsx" */),
  "component---src-pages-procreate-patterns-tsx": () => import("./../../../src/pages/procreate-patterns.tsx" /* webpackChunkName: "component---src-pages-procreate-patterns-tsx" */),
  "component---src-pages-stationery-design-in-procreate-tsx": () => import("./../../../src/pages/stationery-design-in-procreate.tsx" /* webpackChunkName: "component---src-pages-stationery-design-in-procreate-tsx" */),
  "component---src-pages-watercolor-florals-in-procreate-tsx": () => import("./../../../src/pages/watercolor-florals-in-procreate.tsx" /* webpackChunkName: "component---src-pages-watercolor-florals-in-procreate-tsx" */),
  "component---src-pages-watercolor-lettering-in-procreate-tsx": () => import("./../../../src/pages/watercolor-lettering-in-procreate.tsx" /* webpackChunkName: "component---src-pages-watercolor-lettering-in-procreate-tsx" */)
}

