/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { reqAnimFrame } from "./polyfills/reqAnimFrame"
import './src/styles/global.css'

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    console.log(`# IntersectionObserver is polyfilled!`)
    await import(`intersection-observer`)
  }
  if (typeof window.requestAnimationFrame === `undefined`) {
    console.log(`# requestAnimationFrame is polyfilled!`)
    reqAnimFrame()
  }
}

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This page has been updated. ` +
    `Reload to display the latest version!`,
  )
  if (answer === true) {
    window.location.reload()
  }
}
